<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="標題" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入標題" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="用戶昵稱" prop="title">
                <a-input v-model="queryParam.userName" placeholder="请输入用戶昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="類型" prop="type">
                <a-select v-model="queryParam.type" placeholder="请选择類型" allow-clear>
                  <a-select-option :value="1">
                    排位赛
                  </a-select-option>
                  <a-select-option :value="2">
                    晋级赛
                  </a-select-option>
                  <a-select-option :value="3">
                    排位赛联赛
                  </a-select-option>
                  <a-select-option :value="4">
                    晋级赛联赛
                  </a-select-option>
                  <a-select-option :value="5">
                    循环赛
                  </a-select-option>
                  <a-select-option :value="6">
                    根据地
                  </a-select-option>
                  <a-select-option :value="7">
                    循环赛联赛
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 增加修改 -->
      <detail-form
        ref="detailForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <img style="width: 48px;height: 48px;" preview="封面" :src="record.cover" v-if="record.cover != null && record.cover != ''" slot="cover" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.applicationStatus == 0">
            审核
          </a>
          <a-divider type="vertical"/>
          <a @click="$refs.detailForm.handleUpdate(record, undefined)">
            详情
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCompetition, delCompetition } from '@/api/platform/competition'
import CreateForm from './modules/CreateForm'
import DetailForm from './modules/DetailForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Competition',
  components: {
    CreateForm,
    DetailForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        type: null,
        title: null,
        cover: null,
        status: null,
        schedule: null,
        details: null,
        applicationStatus: null,
        refusalCause: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '標題',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '類型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "排位赛"
            }
            if (value == 2) {
              return "晋级赛"
            }
            if (value == 3) {
              return "排位赛联赛"
            }
            if (value == 4) {
              return "晋级赛联赛"
            }
            if (value == 5) {
              return "循环赛"
            }
            if (value == 6) {
              return "根据地"
            }
            if (value == 7) {
              return "循环赛联赛"
            }
          }
        },
        {
          title: '封面',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "cover"}
        },
        {
          title: '阶段',
          dataIndex: 'schedule',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "无"
            }
            if (value == 1) {
              return "初赛"
            }
            if (value == 2) {
              return "复赛"
            }
            if (value == 3) {
              return "决赛"
            }
          }
        },
        {
          title: '赛事狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "报名中"
            }
            if (value == 1) {
              return "报名结束"
            }
            if (value == 2) {
              return "进行中"
            }
            if (value == 3) {
              return "已结束"
            }
          }
        },
        {
          title: '赛事详情',
          dataIndex: 'details',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '審核狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "审核中"
            }
            if (value == 1) {
              return "已审核"
            }
            if (value == 2) {
              return "已拒绝"
            }
          }
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢赛事-信息列表 */
    getList () {
      this.loading = true
     pageCompetition(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        title: undefined,
        cover: undefined,
        status: undefined,
        schedule: undefined,
        details: undefined,
        applicationStatus: undefined,
        refusalCause: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCompetition(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/competition/export', {
            ...that.queryParam
          }, `赛事-信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
